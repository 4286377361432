import React from "react";
import PropTypes from "prop-types";
import { htmlifyMarkdown } from "../../../utils";

import styles from "./TextGroup.module.scss";

const propTypes = {
	header: PropTypes.string,
	body: PropTypes.string,
	headerCenterAlign: PropTypes.bool,
	bodyCenterAlign: PropTypes.bool,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
	header: null,
	body: null,
	headerCenterAlign: false,
	bodyCenterAlign: false,
	className: "",
};

function TextGroup({ header, headerCenterAlign, body, bodyCenterAlign, className }) {
	if (!header && !body) {
		return null;
	}

	return (
		<section className={`${styles.group__wrapper} ${className}`}>
			{header && (
				<div
					className={`${styles.group__header} ${headerCenterAlign ? styles["text--isCenter"] : ""}`}
					dangerouslySetInnerHTML={{ __html: htmlifyMarkdown(header) }}
				/>
			)}
			{body && (
				<div
					className={`${styles.group__body} ${bodyCenterAlign ? styles["text--isCenter"] : ""}`}
					dangerouslySetInnerHTML={{ __html: htmlifyMarkdown(body) }}
				/>
			)}
		</section>
	);
}

TextGroup.propTypes = propTypes;
TextGroup.defaultProps = defaultProps;

export default TextGroup;
