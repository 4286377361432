import React from "react";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";

import styles from "./MultiLayeredGroup.module.scss";

const propTypes = {
	data: PropTypes.array,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
	data: [],
	className: "",
};

function MultiLayeredGroup({ data, className }) {
	if (!data || !isArray(data.imageGroups) || isEmpty(data.imageGroups)) {
		return null;
	}

	const { imageGroups, imageGroupIsFullWidth } = data;

	return (
		<section
			className={`${styles.image__wrapperMain} ${
				!imageGroupIsFullWidth ? styles["is--halfWidth"] : ""
			} ${className}`}
		>
			{imageGroups.map((item, idx) => {
				return idx === 0 ? (
					<div key={uniqid()} className={styles.image__wrapperMaster}>
						<img
							src={item.imageItem}
							className={styles.masterImage}
							style={{ objectFit: item.objectFitType }}
							alt="Visual"
						/>
					</div>
				) : (
					<img
						key={uniqid()}
						src={item.imageItem}
						className={`${styles.slaveImage} ${item.isFullWidth ? styles["is--fullWidth"] : ""}`}
						style={{ objectFit: item.objectFitType }}
						alt="Visual"
					/>
				);
			})}
		</section>
	);
}

MultiLayeredGroup.propTypes = propTypes;
MultiLayeredGroup.defaultProps = defaultProps;

export default MultiLayeredGroup;
