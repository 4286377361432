import React from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";
import { isArray, isEmpty } from "lodash";

import ImageGroup from "../ImageGroup/ImageGroup";
import MultiLayeredGroup from "../MultiLayeredGroup/MultiLayeredGroup";
import TextGroup from "../TextGroup/TextGroup";

import styles from "./WorkContentAssign.module.scss";

const propTypes = {
	data: PropTypes.array,
};
const defaultProps = {
	data: null,
};

function WorkContentAssign({ data }) {
	if (!isArray(data) || isEmpty(data)) {
		return null;
	}

	return (
		<section>
			{data.map((item) => {
				switch (item.type) {
					case "imageGroup":
						return (
							<ImageGroup
								key={uniqid()}
								data={item.imageGroupItem}
								className={styles.groupWrapper}
							/>
						);

					case "multiLayeredGroup":
						return (
							<MultiLayeredGroup
								key={uniqid()}
								data={{
									imageGroups: item.multiLayeredItem,
									imageGroupIsFullWidth: item.multiLayeredGroupFullWidth,
								}}
								className={styles.groupWrapper}
							/>
						);

					case "textGroup":
						return (
							<TextGroup
								key={uniqid()}
								header={item.header}
								headerCenterAlign={item.headerCenterAlign}
								body={item.body}
								bodyCenterAlign={item.bodyCenterAlign}
								className={styles.groupWrapper}
							/>
						);

					default:
						return null;
				}
			})}
		</section>
	);
}

WorkContentAssign.propTypes = propTypes;
WorkContentAssign.defaultProps = defaultProps;

export default WorkContentAssign;
