import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import SEO from "../components/SEO/SEO";
import Masthead from "../components/Work/Masthead/Masthead";
import WorkContentAssign from "../components/Work/WorkContentAssign/WorkContentAssign";
import NextWork from "../components/Work/NextWork/NextWork";

const propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
	uri: PropTypes.string.isRequired,
};

const CaseStudy = ({ data, pageContext, uri }) => {
	const {
		workData: { edges: allWorkData },
		workOrderedList: {
			childMarkdownRemark: {
				frontmatter: { work_ordered_list: workOrderedList },
			},
		},
	} = data;

	let currentWorkData;
	allWorkData.forEach((item) => {
		const {
			node: {
				childMarkdownRemark: {
					fields: { slug },
				},
			},
		} = item;

		if (slug === pageContext.slug) {
			// reference matching data to [currentWorkData]
			currentWorkData = item.node.childMarkdownRemark.frontmatter;
		}
	});

	function getNextWorkItem() {
		const currentWorkIdx = workOrderedList.findIndex(
			(fIdx) => fIdx.work_item.toLowerCase() === currentWorkData.projectTitle.toLowerCase(),
		);
		const nextWorkTitle =
			workOrderedList[currentWorkIdx + 1 < workOrderedList.length ? currentWorkIdx + 1 : 0]
				.work_item;

		const nextWorkData = allWorkData.filter(
			(item) =>
				nextWorkTitle.toLowerCase() ===
				item.node.childMarkdownRemark.frontmatter.projectTitle.toLowerCase(),
		);

		let nextWorkItem =
			Array.isArray(nextWorkData) && nextWorkData.length > 0
				? nextWorkData[0].node.childMarkdownRemark
				: null;

		return nextWorkItem;
	}

	return (
		<>
			<SEO
				title={currentWorkData.project_seo_title || currentWorkData.projectTitle}
				description={currentWorkData.project_seo_description}
				pathname={uri}
				article
			/>

			<div style={{ minHeight: "100%" }}>
				<Masthead
					deliverables={currentWorkData.projectCategory}
					title={currentWorkData.projectTitle}
					description={currentWorkData.projectDescription}
					webUrl={currentWorkData.projectWebUrl}
				/>

				<WorkContentAssign data={currentWorkData.projectContent} />
			</div>

			{getNextWorkItem() && <NextWork data={getNextWorkItem()} />}
		</>
	);
};

CaseStudy.propTypes = propTypes;
export default CaseStudy;

export const query = graphql`
	query {
		workData: allFile(filter: { relativeDirectory: { eq: "work" } }) {
			edges {
				node {
					id
					childMarkdownRemark {
						id
						frontmatter {
							project_seo_title
							project_seo_description
							projectHeroImage
							projectTitle
							projectCategory
							projectContent {
								imageGroupItem {
									imageItem
									isFullWidth
								}
								type
								multiLayeredItem {
									imageItem
									isFullWidth
									objectFitType
								}
								multiLayeredGroupFullWidth
							}
							projectClientCompany
							projectWebUrl
							projectDescription
						}
						fields {
							slug
						}
					}
				}
			}
		}
		workOrderedList: file(name: { eq: "work-sort_order" }) {
			name
			childMarkdownRemark {
				id
				frontmatter {
					work_ordered_list {
						work_item
					}
				}
			}
		}
	}
`;
