import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { truncateStr } from "../../../utils";

import imgArrowHorizontal from "../../../../static/img/ui/arrow-horizontal.svg";

import styles from "./NextWork.module.scss";

const propTypes = {
	data: PropTypes.object,
};

const defaultProps = {
	data: null,
};

function NextWork({ data }) {
	const {
		frontmatter: { projectTitle: title, projectDescription: description },
		fields: { slug },
	} = data;

	return (
		<Link to={slug} className={styles.wrapper__main}>
			<div className={styles.wrapper__content}>
				<Link to={slug} className={styles.button__next}>
					Next Project
					<img src={imgArrowHorizontal} className={styles.ico__arrow} alt={title} />
				</Link>
				<p className={styles.title}>{title}</p>
				<p className={styles.body}>{truncateStr(description)}</p>
			</div>
		</Link>
	);
}

NextWork.propTypes = propTypes;
NextWork.defaultProps = defaultProps;

export default NextWork;
