import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";

import styles from "./ImageGroup.module.scss";

const propTypes = {
	data: PropTypes.array,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
	data: [],
	className: "",
};

function ImageGroup({ data, className }) {
	if (!isArray(data) || isEmpty(data)) {
		return null;
	}

	const [index, setIndex] = useState(0);
	const sliderRef = useRef();

	if (data.length === 1) {
		return (
			<img
				src={data[0].imageItem}
				alt="Visual"
				className={`${styles.imageMainWrapper} ${
					data[0].isFullWidth ? styles["image--isFullWidth"] : ""
				} ${className}`}
			/>
		);
	}

	return (
		<div className={`${styles.carouselSection__wrapperMain} ${className}`}>
			<Slider
				ref={sliderRef}
				className={styles.imageMainWrapper}
				autoplay
				afterChange={(idx) => setIndex(idx)}
			>
				{data.map((item) => (
					<div key={uniqid()} className={styles.carousel__itemWrapper}>
						<img
							src={item.imageItem}
							alt="Visual"
							className={`${styles.carousel__itemImgEl} ${
								item.isFullWidth ? styles["image--isFullWidth"] : ""
							}`}
						/>
					</div>
				))}
			</Slider>

			<div className={styles.carouselDots__wrapper}>
				{data.map((item, idx) => {
					return (
						<button
							key={uniqid()}
							className={`${styles.carouselDots__item} ${
								index === idx ? styles["is--active"] : ""
							}`}
							onClick={() => sliderRef.current.slickGoTo(idx)}
							aria-label="Carousel pagination"
							type="button"
						/>
					);
				})}
			</div>
		</div>
	);
}

ImageGroup.propTypes = propTypes;
ImageGroup.defaultProps = defaultProps;

export default ImageGroup;
