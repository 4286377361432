import React from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";

import { htmlifyMarkdown } from "../../../utils";

import styles from "./Masthead.module.scss";

const propTypes = {
	deliverables: PropTypes.array,
	title: PropTypes.string,
	description: PropTypes.string,
	webUrl: PropTypes.string,
};

const defaultProps = {
	deliverables: null,
	title: "",
	description: "",
	webUrl: "",
};

function Masthead({ deliverables, title, description, webUrl }) {
	return (
		<section className={styles.wrapper__main}>
			{isArray(deliverables) && !isEmpty(deliverables) && (
				<section className={styles.wrapper__col}>
					<div className={styles.deliverable__wrapper}>
						<div className={styles.deliverable__title}>Deliverables</div>
						{deliverables.map((item) => {
							return (
								<div
									key={uniqid()}
									className={styles.deliverable__item}
									onClick={() => navigate(`/work?tag=${item.toLowerCase()}`)}
									role="presentation"
								>
									{item}
								</div>
							);
						})}
					</div>
				</section>
			)}

			<section className={styles.wrapper__col}>
				<h1 className={styles.project__title}>{title}</h1>
				<h2
					className={styles.project__description}
					dangerouslySetInnerHTML={{ __html: htmlifyMarkdown(description) }}
				/>
				{webUrl && (
					<a
						href={webUrl}
						target="_blank"
						rel="noopener noreferrer"
						className={styles.project__btnWebLink}
					>
						{webUrl}
					</a>
				)}
			</section>
		</section>
	);
}

Masthead.propTypes = propTypes;
Masthead.defaultProps = defaultProps;

export default Masthead;
